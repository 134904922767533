import { Field, Form, Formik } from "formik";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import moment from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import { ControlLabel } from "react-bootstrap";
import "react-day-picker/lib/style.css";
import ReactPixel from "react-facebook-pixel";
import InputMask from "react-input-mask";
import Moment from "react-moment";
import * as Yup from "yup";
import mainLogo from "../../assets/img/emersonLogo.jpg";
import history from "../../history";
import {
  m1PhoneForRegion,
  regionForDomain,
  timezoneForRegion,
} from "../../Shared/locations";
import BookingService from "../../utils/BookingService";
import "./Booking.css";

class Booking extends Component {
  constructor(props) {
    super(props);
    this.onSlotClick = this.onSlotClick.bind(this);
    this.submitBooking = this.submitBooking.bind(this);

    let referrerInfo;
    let params = queryString.parse(this.props.location.search);
    if (this.props.location.state && !this.props.location.state.seminar_id) {
      referrerInfo = {
        source: "trial_coaching_session",
        data: this.props.location.state,
      };
    } else {
      referrerInfo = { source: params.booking_from, data: params };
    }

    const region = referrerInfo.data.region || regionForDomain();
    const timezone = timezoneForRegion(region);

    const action = referrerInfo.data.help || "book";
    const adminResheduling = params.admin_resheduling === "true";
    let fromDate;
    if (action == "reschedule") {
      fromDate = moment(new Date())
        .add(
          referrerInfo.data.m1_booking_user && adminResheduling ? 0 : 2,
          "days"
        )
        .toDate();
    } else {
      fromDate = moment(new Date())
        .add(referrerInfo.data.m1_booking_user ? 0 : 2, "days")
        .toDate();
    }
    let toDate = moment(new Date())
      .add(referrerInfo.data.m1_booking_user ? 29 : 17, "days")
      .toDate();

    let reseduleAsNewDeal = params.create_new_deal === "true";
    let assignDealToMyself = params.assign_deal_to_me === "true";
    this.state = {
      region: region,
      timezone: timezone,
      fromDate: fromDate,
      toDate: toDate,
      dateRange: this.getDateRange(fromDate, toDate),
      visibleSlots: {},
      selectedDay: fromDate,
      referrerInfo: referrerInfo,
      redirectUrl: params.redirect_url,
      reseduleAsNewDeal: reseduleAsNewDeal,
      assignDealToMyself: assignDealToMyself,
    };
  }

  componentDidMount() {
    if (this.state.region) this.fetchSlots();
    ReactPixel.pageView();
  }

  setRegion(region) {
    this.setState({
      chosenSlot: null,
      region: region,
      timezone: timezoneForRegion(region),
    });
    this.fetchSlots();
  }

  fetchSlots() {
    this.setState({ loading: true });
    this.state.dateRange.forEach((date) => {
      const dt = date.toDate();
      BookingService.freeSlots({ from_date: dt, to_date: dt }).then((data) => {
        const enriched = this.enrichSlots(data.slots);
        const copy = this.state.visibleSlots;
        Object.keys(enriched).forEach((k) => {
          copy[k] = enriched[k];
        });
        this.setState({ loading: false, visibleSlots: copy });
      });
    });
  }

  enrichSlots(slots) {
    // Slots are always returned in local time for Office - ie Melbourne
    let toReturn = {};
    if (!slots) return toReturn;

    Object.keys(slots).forEach((dateKey) => {
      const dateSlots = [];
      Object.keys(slots[dateKey]).forEach((slot) => {
        let bits = slot.split(":");
        let at = moment
          .tz(new Date(dateKey), "Australia/Melbourne")
          .set({ hour: bits[0], minute: bits[1] });
        let atLocal = at.clone().tz(this.state.timezone);

        const coachCount = slots[dateKey][slot].coach_count;
        const dealCount = slots[dateKey][slot].deal_count;
        const overbookCapacity =
          coachCount === 0 ? 0 : Math.ceil(coachCount / 5.0) + 1;
        const calculatedCapacity = coachCount + overbookCapacity;
        const totalCapacity = calculatedCapacity - dealCount;

        if (totalCapacity > 0) {
          dateSlots.push({
            key: slot,
            full: totalCapacity === 0,
            display: atLocal.format("h:mma"),
            at: atLocal,
            coachCount: coachCount,
            deals: dealCount,
            overbookCapacity: overbookCapacity,
            calculatedCapacity: calculatedCapacity,
            totalCapacity: totalCapacity,
          });
        }
      });
      toReturn[dateKey] = dateSlots;
    });
    return toReturn;
  }

  getDateRange(from, to) {
    var dateArray = [];
    var currentDate = moment(from);
    var stopDate = moment(to);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate));
      currentDate = moment(currentDate).add(1, "days");
    }
    return dateArray;
  }

  // isSlotCapacityFull(slot) {
  //   if (slot.total_confirmed >= slot.coach_count) return true
  //   if (slot.seminar >= slot.coach_count) return true

  //   const freeCoaches = slot.coach_count - slot.total_confirmed_not_seminar - slot.seminar // how many coaches are free, semianrs and confirmed
  //   if (freeCoaches <= 0) return true // there arent any free coaches

  //   const coachCapacity = 2 // how many bookings per coach
  //   const totalCapacity = freeCoaches * coachCapacity

  //   return (slot.m1office + slot.web + slot.call_centre) >= totalCapacity
  // }

  onSlotClick(slot) {
    this.setState({ chosenSlot: slot.at });

    // BookingService.freeSlots({ from_date: slot.at.format('YYYY-MM-DD'), to_date: slot.at.format('YYYY-MM-DD') }).then(data => {
    //   const officeTime = slot.at.clone().tz("Australia/Melbourne")
    //   const dateKey = officeTime.format('YYYY-MM-DD')
    //   const slotKey = officeTime.format('HH:mm')

    //   const unavailable = this.isSlotCapacityFull(data.slots[dateKey][slotKey])

    //   // TODO block booking this time as its already overbooked
    //   this.setState({ chosenSlot: slot.at })
    // })
  }

  resetChosenSlot() {
    this.setState({ chosenSlot: null });
    this.fetchSlots();
  }

  renderLoading = () => {
    return (
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 50,
        }}
      >
        <div className="lds-grid">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h3 className="lds-grid-loading">Finding Available Times</h3>
      </div>
    );
  };

  renderTimeSlots = () => {
    // const officeTime = this.state.chosenSlot ? this.state.chosenSlot.tz("Australia/Melbourne").format("h:mma Do MMMM YYYY") : ''
    const localTime = this.state.chosenSlot
      ? this.state.chosenSlot
          .tz(this.state.timezone)
          .format("h:mma Do MMMM YYYY")
      : "";

    return (
      <div className="time-slots">
        <div>
          {this.state.loading && (
            <div className="time-slots-overlay">{this.renderLoading()}</div>
          )}
          {this.state.referrerInfo.data.m1_booking_user && (
            <>
              <p>Booking numbers are: Seminar / Call Centre / Web / Office</p>
              <p>
                Try to find a slot with the lowest number of existing bookings
              </p>
              <p>
                Greyed out time slots are fully booked, you can overbook only if
                no other slot will suit the customer. Avoid overbooking if the
                number Confirmed is equal to or greater than the number of
                Coaches
              </p>
            </>
          )}

          {this.state.chosenSlot ? (
            <div>
              <h2 style={{ color: "#4A90E2" }}>
                {localTime} in&nbsp;{this.state.timezone.split("/")[1]}&nbsp;
                {this.state.region}{" "}
                <button
                  className="btn btn-sm btn-link"
                  onClick={() => this.resetChosenSlot()}
                >
                  choose a different time
                </button>
              </h2>
              {this.renderBookingForm()}
              <div style={{ marginTop: "15px" }}></div>
            </div>
          ) : (
            Object.keys(this.state.visibleSlots).length > 0 &&
            this.state.dateRange.map((date) => {
              const dateKey = date.format("YYYY-MM-DD");
              return (
                <div className="day-division">
                  <h3>
                    <Moment format="dddd Do MMMM YYYY">{date}</Moment>
                  </h3>
                  {this.renderAvailableSlots(dateKey)}
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  };

  renderAvailableSlots = (dateKey) => {
    return (
      <div>
        {this.renderDayDivision(this.state.visibleSlots[dateKey], false)}
        {this.state.referrerInfo.data.m1_booking_user &&
          this.renderDayDivision(this.state.visibleSlots[dateKey], true)}
      </div>
    );
  };

  renderDayDivision = (slots, overbooking) => {
    const m1User = this.state.referrerInfo.data.m1_booking_user;
    if (!slots)
      return overbooking ? null : <p>Sorry, this has been fully booked</p>;
    if (!overbooking) slots = slots.filter((slot) => !slot.full);
    if (overbooking) slots = slots.filter((slot) => slot.full);
    if (slots.length === 0) {
      return overbooking ? null : <p>Sorry, this has been fully booked</p>;
    }

    return (
      <>
        {overbooking && (
          <div style={{ marginTop: 30 }}>
            <h3 style={{ color: "rgb(226, 74, 74)" }}>
              OVERBOOKING: These slots are at capacity
            </h3>
            <h5>
              Only book one of these if you <strong>cannot</strong> find a free
              slot in another suitable time slot
            </h5>
          </div>
        )}
        {slots.map((slot) => {
          if (slot.full && !overbooking) return null;
          if (!slot.full && overbooking) return null;
          let cname = "time-slot";
          if (m1User) cname += " m1-user";
          if (slot.full) cname += " full";

          return (
            <span
              key={slot.key}
              className={cname}
              onClick={() => this.onSlotClick(slot)}
            >
              {slot.display}
              <div className="info">
                <div>
                  <small>Coaches: {slot.coachCount}</small>
                </div>
                <div>
                  <small>Deals: {slot.deals}</small>
                </div>
              </div>
            </span>
          );
        })}
      </>
    );
  };

  submitBooking = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    let data = {
      at: this.state.chosenSlot,
      m1_booking_user: this.state.referrerInfo.data.m1_booking_user,
      deal_id: this.state.referrerInfo.data.deal_id,
      lead_id: this.state.referrerInfo.data.lead_id,
      utm_source: this.state.referrerInfo.data.utm_source || "",
      utm_medium: this.state.referrerInfo.data.utm_medium || "",
      utm_campaign: this.state.referrerInfo.data.utm_campaign || "",
      utm_content: this.state.referrerInfo.data.utm_content || "",
      utm_term: this.state.referrerInfo.data.utm_term || "",
      reschedule_as_new_deal: this.state.reseduleAsNewDeal || false,
      assign_deal_to_me: this.state.assignDealToMyself || false,
    };
    data = Object.assign(data, values);
    const bookingServicePromise = this.state.reseduleAsNewDeal
      ? BookingService.resheduleTCSAsNewDeal(data)
      : BookingService.bookTrialCoachingSession(data);
    bookingServicePromise
      .then((data) => {
        data.timezone = this.state.timezone;
        data.redirectUrl = this.state.redirectUrl;
        history.push("/booking_confirmed", data);
      })
      .catch((error) => {
        console.debug(error);
        let errors = {};
        Object.keys(error.data).map(
          (key) => (errors[key] = error.data[key].join(", "))
        );
        setErrors(errors);
        setSubmitting(false);
      });
  };

  renderBookingForm = () => {
    const always = {
      notes: Yup.string().max(500, "maximum 500 characters"),
    };

    const clientInfo = {
      region: Yup.string().required("is required"),
      first_name: Yup.string()
        .max(30, "maximum 30 characters")
        .required("is required"),
      email: Yup.string()
        .max(100, "maximum 100 characters")
        .email("doesn't look like a valid email address")
        .required("is required"),
      mobile: Yup.string()
        .matches(/04\d\d \d\d\d \d\d\d/, "must be an Australian mobile number")
        .required("is required")
        .test("is-valid", "must be an Australian mobile number", (value) => {
          if (!value) return false;
          let phoneNumber = parsePhoneNumberFromString(value, "AU");
          if (!phoneNumber) return false;
          return phoneNumber.isValid();
        }),
      student_first_name: Yup.string()
        .max(50, "maximum 50 characters")
        .required("is required"),
      business_stream: Yup.string().required("is required"),
      booking_from: Yup.string().required("is required"),
    };

    const validationSchema = this.state.referrerInfo.data.deal_id
      ? Yup.object().shape(always)
      : Yup.object().shape({ ...always, ...clientInfo });

    return (
      <Formik
        initialValues={{
          region: this.state.region,
          notes: "",
          booking_from:
            this.state.referrerInfo.data.booking_from || "emersonwebinar",
          business_stream:
            this.state.referrerInfo.data.business_stream ||
            this.state.referrerInfo.data.booking_from ||
            "webinar",
          email: this.state.referrerInfo.data.email,
          first_name: this.state.referrerInfo.data.first_name,
          mobile: this.state.referrerInfo.data.mobile,
          student_first_name: this.state.referrerInfo.data.student_first_name,
        }}
        validationSchema={validationSchema}
        onSubmit={this.submitBooking}
        render={({ values, errors, touched, isSubmitting }) => (
          <Form style={{ maxWidth: 600 }}>
            {!this.state.referrerInfo.data.deal_id && (
              <div>
                <input
                  type="hidden"
                  id="region"
                  name="region"
                  value={values.region}
                />

                <div
                  className={`form-group ${
                    errors.email && touched.email && "has-error"
                  }`}
                >
                  <ControlLabel>Parent's email address</ControlLabel>
                  <Field
                    autoFocus
                    type="text"
                    name="email"
                    className="form-control"
                    placeholder="Parent's email address"
                  />
                  {errors.email && touched.email && (
                    <p className="help-block">{errors.email}</p>
                  )}
                </div>

                <div
                  className={`form-group ${
                    errors.first_name && touched.first_name && "has-error"
                  }`}
                >
                  <ControlLabel>Parent's first name</ControlLabel>
                  <Field
                    type="text"
                    name="first_name"
                    className="form-control"
                    placeholder="Parent's first name"
                  />
                  {errors.first_name && touched.first_name && (
                    <p className="help-block">{errors.first_name}</p>
                  )}
                </div>

                <div
                  className={`form-group ${
                    errors.mobile && touched.mobile && "has-error"
                  }`}
                >
                  <ControlLabel>Parent's mobile number</ControlLabel>
                  <Field
                    name="mobile"
                    render={({ field }) => {
                      return (
                        <InputMask
                          mask="9999 999 999"
                          {...field}
                          className="form-control"
                          placeholder="Parent's mobile number"
                        />
                      );
                    }}
                  />
                  {errors.mobile && touched.mobile && (
                    <p className="help-block">{errors.mobile}</p>
                  )}
                </div>

                <div
                  className={`form-group ${
                    errors.student_first_name &&
                    touched.student_first_name &&
                    "has-error"
                  }`}
                >
                  <ControlLabel>Student's first name</ControlLabel>
                  <Field
                    type="text"
                    name="student_first_name"
                    className="form-control"
                    placeholder="Student first name"
                  />
                  {errors.student_first_name && touched.student_first_name && (
                    <p className="help-block">{errors.student_first_name}</p>
                  )}
                </div>
              </div>
            )}

            {this.state.referrerInfo.data.m1_booking_user && (
              <React.Fragment>
                <div>
                  <div
                    className={`form-group ${
                      errors.booking_from && touched.booking_from && "has-error"
                    }`}
                  >
                    <ControlLabel>Booking from</ControlLabel>
                    <Field
                      className="form-control"
                      name="booking_from"
                      component="select"
                    >
                      <option value="">-- select --</option>
                      <option value="seminar">Seminar</option>
                      <option value="web">Web</option>
                      <option value="call_centre">Call Centre</option>
                      <option value="m1office">M1 Office</option>
                      <option value="tcs_coach">TCS Coach</option>
                      <option value="emersonwebinar">Emerson Webinar</option>
                    </Field>
                    {errors.booking_from && touched.booking_from && (
                      <p className="help-block">{errors.booking_from}</p>
                    )}
                  </div>
                  <div
                    className={`form-group ${
                      errors.business_stream &&
                      touched.business_stream &&
                      "has-error"
                    }`}
                  >
                    <ControlLabel>Business stream / source</ControlLabel>
                    <Field
                      className="form-control"
                      name="business_stream"
                      component="select"
                    >
                      <option value="">-- select --</option>
                      <option value="seminar">Seminar</option>
                      <option value="web">Web Booking</option>
                      <option value="webinar">Webinar</option>
                      <option value="callcentre">Call Centre</option>
                      <option value="emersonwebinar">Emerson Webinar</option>
                      <option value="work_experience_directory">
                        Work Experience Directory
                      </option>
                      <option value="free_report">Free Report</option>
                      <option value="seminar_confirmation_call">
                        Seminar Confirmation Call
                      </option>
                      <option value="seminar_no_show">
                        Seminar No Show Call
                      </option>
                      <option value="seminar_attended_didnt_book">
                        Seminar Attended But Didnt Book
                      </option>
                      <option value="referral">Referral</option>
                      <option value="webinar_no_show">Webinar No Show</option>
                      <option value="other">Other</option>
                    </Field>
                    {errors.business_stream && touched.business_stream && (
                      <p className="help-block">{errors.business_stream}</p>
                    )}
                  </div>
                </div>
                <div
                  className={`form-group ${
                    errors.notes && touched.notes && "has-error"
                  }`}
                >
                  <ControlLabel>Notes or comments?</ControlLabel>
                  <Field
                    style={{ height: "100px" }}
                    component="textarea"
                    name="notes"
                    className="form-control"
                    placeholder="Any notes or comments?"
                  />
                  {errors.notes && touched.notes && (
                    <p className="help-block">{errors.notes}</p>
                  )}
                </div>
              </React.Fragment>
            )}

            <div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-info btn"
              >
                Confirm and reserve this time
              </button>
            </div>
          </Form>
        )}
      />
    );
  };

  render() {
    return (
      <div className="container booking-page">
        <div className="row">
          <div className="col-sm-12">
            <div style={{ marginTop: "20px", marginBottom: "10px" }}>
              <img src={mainLogo} alt="" />
            </div>
            <h3 style={{ color: "#4A90E2" }}>
              Book Your Free Coaching Session{" "}
              {this.state.region && (
                <>
                  in {this.state.region}{" "}
                  <button
                    className="btn btn-sm btn-link"
                    onClick={() =>
                      this.setState({
                        chosenSlot: undefined,
                        region: undefined,
                        timezone: undefined,
                      })
                    }
                  >
                    change location
                  </button>
                </>
              )}
            </h3>

            {!this.state.region ? (
              <div className={`form-group`} style={{ width: 300 }}>
                <label>Where does your student study?</label>
                <select
                  component="select"
                  name="region"
                  className="form-control"
                  onChange={(e) => this.setRegion(e.target.value)}
                >
                  <option value="">-- pick your state --</option>
                  <option value="ACT">
                    Australian Capital Territory (SSC)
                  </option>
                  <option value="NSW">New South Wales (HSC)</option>
                  <option value="NT">Northern Territory (NTCET)</option>
                  <option value="QLD">Queensland (QCE)</option>
                  <option value="SA">South Australia (SACE)</option>
                  <option value="TAS">Tasmania (TCE)</option>
                  <option value="VIC">Victoria (VCE)</option>
                  <option value="WA">Western Australia (WACE)</option>
                </select>
              </div>
            ) : (
              <>
                {!this.state.referrerInfo.data.m1_booking_user && (
                  <>
                    <p>
                      You can book your session by choosing a date and time that
                      suits you below. All time slots are shown in{" "}
                      {this.state.region} time. If you can't find a time slot to
                      suit, don't miss out, you can call us on{" "}
                      {m1PhoneForRegion(this.state.region)} or email us{" "}
                      <a href="mailto:contact@emersoncoaching.com.au">
                        contact@emersoncoaching.com.au
                      </a>{" "}
                      and we will find a time to suit you.
                    </p>
                    <p>
                      Our appointments are one-on-one with a highly qualified
                      coach and have limited availability, so please make sure
                      you and your student will be available at the chosen time
                      before booking.
                    </p>
                  </>
                )}

                {this.renderTimeSlots()}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default Booking;
