import React, { Component } from "react";
import HomeDanWistia from './HomeDanWistia';

class HomeDan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: props.region,
      exam: props.exam,
    }
  }

  render() {
    return (
      <section className="dan">
        <div className="container">
          <h2>Dan's Story</h2>
          <HomeDanWistia />
          <p>
            Hi, Dan Dobos - founder of Emerson Coaching - here. When I was in high
            school, I always wanted to do well.
          </p>
          <p>
            You see, my parents migrated to Australia from a small Eastern
            European country called Romania.
          </p>
          <p>
            They packed their bags and left everything behind to build a better
            life here in Australia.
          </p>
          <p>
            They started life here with absolutely nothing. In fact, I even
            remember my dad telling me they couldn't even afford to buy any
            chairs to sit on - they had to use cardboard boxes instead.
          </p>
          <p>
            Keen to make the most of the sacrifices my parents made, I always
            sought to do my best at school. I spent 2-3 hours a night studying
            in high school when my friends would be outside or playing video
            games.
          </p>
          <p>
            You would think that because of all of the time spent studying, I
            would be someone who did well at school.
          </p>
          <p>Well, I wasn't.</p>
          <p>
            Despite spending HOURS every day studying, the best I could score in
            Year 10 and 11 were B's and C's.
          </p>
          <p>
            In fact, there was one time in Year 11 where I handed in an essay
            and my teacher refused to give me a grade for it and instead gave me
            a UG ie UNGRADEABLE :(
          </p>
          <p>My essay was that bad!</p>
          <p>
            I remember always feeling frustrated and disappointed in myself
            because I saw my classmates - most of who spent a FRACTION of the
            time I did studying - fly ahead of me in class and score better
            marks.
          </p>
          <p>
            I remember always thinking to myself, "I should be doing so much
            better than this."
          </p>
          <p>Then one day, towards the end of Year 11, it hit me:</p>
          <p>
            There's no point working really hard if you don't know what you're
            doing or spending your time on the wrong things!
          </p>
          <p>
            So in Year 12, I decided to approach my school subjects differently.
          </p>
          <p>
            I found some mentors who made realise that there's no point looking
            over my notes 'hoping' that I would understand and get better marks…
          </p>
          <p>
            I was shown the best learning strategies and systematically
            developed better study processes.
          </p>
          <p>
            And that's when I started to unlock my true potential and my marks
            shot up.
          </p>
          <p>
            In fact, at the end of Year 12, I was able to score an ATAR of 99.25
            in the exams - a far cry from the B's and C's I'd been getting in Year
            10 and 11.
          </p>
          <p>
            I was stoked, my parents couldn't be happier and I got into a
            university course I would have never thought possible back in Year
            10 and 11: Law and Engineering at Melbourne University.
          </p>
          <p>
            Since then, I've gone on to create Emerson Coaching - a coaching company
            dedicated to helping high school students reach their full potential
            and score top marks in the exams.
          </p>
          <p>
            63% of our students have used these strategies to achieve an ATAR of
            above 90.
          </p>
          <p>
            I'm dedicated to helping as many students as possible unlock their
            potential, get the marks they deserve and secure a better future.
          </p>
          <p>
            And that's why I'm proud to be sharing our BEST exam success
            strategies in in 30 minute Exam Success seminars.
          </p>
          <h3>
            Reserve your FREE exclusive exam Success Seminars tickets before they
            run out
          </h3>

        </div>

      </section>
    );
  }
}
export default HomeDan;
