import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import "./css/bootstrap.css";
import "./css/style.css";
import history from "./history";
import Home from "./Public/HomePage/Home";
import JoinWebinar from "./Public/HomePage/JoinWebinar";
import ThankYouSeminar from "./Public/ThankYou/ThankYouSeminar";
import Booking from "./Public/TrialCoachingSessionBooking/Booking";
import BookingConfirmed from "./Public/TrialCoachingSessionBooking/BookingConfirmed";
import Analytics from "./utils/Analytics";

Analytics.init();
const MainRoutes = (props) => {
  return (
    <Router history={history}>
      <div>
        <Switch>
          {/* <Route exact path="/" render={(props) => <Home {...props} />} /> */}
          <Route exact path="/" render={(props) => <Booking {...props} />} />
          <Route
            exact
            path="/thankyou"
            render={(props) => <ThankYouSeminar {...props} />}
          />
          <Route
            exact
            path="/booking_confirmed"
            render={(props) => <BookingConfirmed {...props} />}
          />
          {/* <Route
            exact
            path="/booking"
            render={(props) => <Booking {...props} />}
          /> */}

          <Route
            exact
            path="/pass"
            render={(props) => (
              <Home
                {...props}
                utm_source="expo"
                utm_medium="flyer"
                utm_campaign="postform"
                utm_content="blue2side"
              />
            )}
          />

          <Route
            exact
            path="/join_webinar/:webinar"
            render={(props) => <JoinWebinar {...props} />}
          />

          <Route
            exact
            path="/:region"
            render={(props) => <Home {...props} />}
          />
          <Route component={Home} region="nsw" />
        </Switch>
      </div>
    </Router>
  );
};

export default MainRoutes;
