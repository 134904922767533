import moment from 'moment';
import queryString from 'query-string';
import React, { Component } from "react";
import ReactPixel from 'react-facebook-pixel';
import mainLogo from "../../assets/img/emersonLogo.jpg";
import { timezoneForRegion } from '../../Shared/locations';
import RegistrationService from '../../utils/RegistrationService';
import "./JoinWebinar.css";

class JoinWebinar extends Component {

  constructor(props) {
    super(props)
    let params = queryString.parse(props.location.search)
    this.state = {
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
      nowplus: params.nowplus,
      nowminus: params.nowminus
    }
  }

  componentDidMount() {
    ReactPixel.pageView();

    RegistrationService.getWebinarInfo(this.props.match.params.webinar).then((response) => {
      if (response.id) {
        if (this.state.nowplus) {
          response.at = moment().add('minutes', parseInt(this.state.nowplus)).toISOString()
        }
        if (this.state.nowminus) {
          response.at = moment().subtract('minutes', parseInt(this.state.nowminus)).toISOString()
        }
        return this.setState({ webinar: response }, this.setTimer)
      }
      else {
        this.setState({ error: true })
      }
    })
	}

	componentWillUnmount() {
		if(this.interval) {
			clearInterval(this.interval);
		}
  }

  timerTick = () => {
    const { webinar } = this.state;

    if (!webinar) return
    const then = moment(webinar.at);
    const now = moment(Date.now());
    const duration = moment.duration(then.diff(now));

    const days = parseInt(duration.asDays());
    const hours = parseInt(duration.asHours()) - days * 24;
    const minutes = parseInt(duration.asMinutes()) - (days > 0 ? days * 24 * 60 : 0) - (hours > 0 ? hours * 60 : 0)
    const seconds = parseInt(duration.asSeconds()) - (days > 0 ? days * 24 * 60 * 60 : 0) - (hours > 0 ? hours * 60 * 60 : 0) - (minutes > 0 ? minutes * 60 : 0)

    const thirty_mins_before = moment(webinar.at).subtract('minutes', 30)
    const thirty_mins_after = moment(webinar.at).add('minutes', 30)
    const isOver = moment().isAfter(thirty_mins_after)
    const showJoin = moment().isAfter(thirty_mins_before) && !isOver
    const isInProgress = moment().isAfter(moment(webinar.at).subtract('seconds', 5)) && !isOver

    this.setState({ days, hours, minutes, seconds, showJoin, isOver, isInProgress });
  }

  setTimer = () => {
    this.interval = setInterval(this.timerTick, 1000);
  }

  render() {
    const { days, hours, minutes, seconds, webinar, error, isInProgress, showJoin, isOver } = this.state;

    if (!webinar && !error) return null

    if (webinar && isOver) {
      return <>
        <div style={{ marginTop: "30px", textAlign: 'center' }}>
          <img src={mainLogo} alt="" />
        </div>
          <div className='counter-timer'>
            <h1>Sorry, this webinar has ended</h1>
            <p>The webinar has ended, but we run them on a regular basis</p>
            <p>Please contact us at <a href='mailto:contact@emersoncoaching.com.au'>contact@emersoncoaching.com.au</a> to book a new time</p>
          </div>
      </>
    }

    if (error) {
      return <>
        <div style={{ marginTop: "30px", textAlign: 'center' }}>
          <img src={mainLogo} alt="" />
        </div>
          <div className='counter-timer'>
            <h1>Sorry, we had a problem</h1>
            <p>The webinar you tried to join is no longer running</p>
            <p>Please contact us at <a href='mailto:contact@emersoncoaching.com.au'>contact@emersoncoaching.com.au</a> to book a new time</p>
          </div>
      </>
    }

    return (
        <div>
          <div style={{ marginTop: "30px", textAlign: 'center' }}>
          <img src={mainLogo} alt="" />
        </div>

        <div className='counter-timer'>
          <h1>{webinar.name}</h1>
          <h4>{moment(webinar.at).tz(timezoneForRegion(webinar.user_region)).format('ddd Do MMM h:mma')} in {webinar.user_region} time</h4>
         {!isInProgress && !isOver && <div className='countdown-wrapper'>
              {days > 0 && <div className='countdown-item'>
                {days}
                <span>days</span>
              </div>}

              {(days > 0 || hours > 0) && <div className='countdown-item'>
                {hours}
                <span>hours</span>
              </div>}


              {(days > 0 || hours > 0 || minutes > 0) && <div className='countdown-item'>
                {minutes}
                <span>minutes</span>
              </div>}


              {(days > 0 || hours > 0 || minutes > 0 || seconds > 0) && <div className='countdown-item'>
                {seconds}
                <span>seconds</span>
              </div>}
          </div>}

          {showJoin && <>
            {isInProgress
            ? <h4 style={{marginTop: 20}}>The webinar is under way right now! </h4>
            : <h4>Starting very shortly ... </h4>}
            <h1><a href={webinar.join_url} style={{textDecoration: 'underline'}}>Join the Webinar</a></h1>
          </>}
			</div>

      </div>
    );
  }
}
export default JoinWebinar;
