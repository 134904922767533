function regionForDomain() {
  const domains = {
    "www.vceseminar.com.au": "VIC",
    "vceseminar.com.au": "VIC",
    "www.wacesuccess.com.au": "WA",
    "wacesuccess.com.au": "WA",
    "www.hscsuccess.com.au": "NSW",
    "hscsuccess.com.au": "NSW",
    "www.sacesuccess.com.au": "SA",
    "sacesuccess.com.au": "SA",
    "www.qcesuccess.com.au": "QLD",
    "qcesuccess.com.au": "QLD",
    "www.tcesuccess.com.au": "TAS",
    "tcesuccess.com.au": "TAS",
    "www.ntcetsuccess.com.au": "NT",
    "ntcetsuccess.com.au": "NT",
    "www.scsuccess.com.au": "ACT",
    "scsuccess.com.au": "ACT",
  };
  return domains[window.location.hostname];
}

function examForRegion(region) {
  if (!region || region === "") return "ATAR";
  const domains = {
    VIC: "VCE",
    WA: "WACE",
    NSW: "HSC",
    SA: "SACE",
    QLD: "QACE",
    NT: "NTCET",
    TAS: "TCE",
    ACT: "SSC",
  };
  return domains[region ? region.toUpperCase() : "NOPE"];
}

function timezoneForRegion(region) {
  const tzs = {
    NSW: "Australia/Sydney",
    ACT: "Australia/Canberra",
    WA: "Australia/Perth",
    NT: "Australia/Darwin",
    TAS: "Australia/Hobart",
    VIC: "Australia/Melbourne",
    SA: "Australia/Adelaide",
    QLD: "Australia/Brisbane",
  };
  return tzs[region ? region.toUpperCase() : "NOPE"];
}

function m1PhoneForRegion(region) {
  const numbers = {
    NSW: "0489 077 741",
    ACT: "0489 077 741",
    WA: "0489 077 741",
    NT: "0489 077 741",
    TAS: "0489 077 741",
    VIC: "0489 077 741",
    SA: "08 7228 0257",
    QLD: "0489 077 741",
  };
  return numbers[region ? region.toUpperCase() : "NOPE"];
}

export { examForRegion, m1PhoneForRegion, regionForDomain, timezoneForRegion };
