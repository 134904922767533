import moment from 'moment';
import queryString from 'query-string';
import React, { Component } from "react";
import AddToCalendar from "react-add-to-calendar";
import ReactPixel from 'react-facebook-pixel';
import mainLogo from "../../assets/img/emersonLogo.jpg";
import "../../css/Home.css";
import "../../css/ThankYou.css";
import { examForRegion, regionForDomain } from '../../Shared/locations';

class ThankYouSeminar extends Component {
  constructor(props) {
    let params = queryString.parse(props.location.search)
    super(props);
    this.debugMode = params.debug
    const region = params.region || regionForDomain()
    this.state = {
      region: region,
      exam: examForRegion(region),
    }
  }

  componentDidMount() {
    ReactPixel.pageView();
  }

  render() {
    const data = this.props.location.state

    const event = {
      title: "HSC Success Seminar",
      description:
        "HSC Success Seminar with Emerson Coaching - contact@emersoncoaching.com.au - 0489 077 741",
      location: data.location + ", " + data.address,
      startTime: data.starts,
      endTime: data.ends,
    };
    return (
      <div className="home-page">
        <div className="container">
          {data.redirectUrl
            ? <div>
              <div className="col-sm-6 col-sm-offset-3">
                <div style={{ marginTop: "30px" }}>
                  <img src={mainLogo} alt="" />
                </div>
                <h2 style={{ color: '#4A90E2' }}>Your seats are confirmed...</h2>
                <div className="location">
                  <h3 className='at'>{moment.tz(data.starts, "Australia/Melbourne").format("dddd, MMMM Do YYYY, h:mma")}</h3>
                </div>
                <div className="thing-to-do">
                  <h4>1. Ask the attendee to add the date and time to their diary</h4>
                </div>
                <div className="thing-to-do">
                  <h4>2. Ask them to check for a confirmation SMS and email</h4>
                </div>
                <div className="thing-to-do">
                  <h4><a href={data.redirectUrl}>back to the dashboard</a></h4>
                </div>
              </div>
            </div>
            : <div className="row">
              <div className="col-sm-6 col-sm-offset-3">
                <div style={{ marginTop: "30px" }}>
                  <img src={mainLogo} alt="" />
                </div>
                <h2 style={{ color: '#4A90E2' }}>Your seats are confirmed...</h2>
                <div className="location">
                  <h5 className='at'>{moment.tz(data.starts, "Australia/Melbourne").format("dddd, MMMM Do YYYY, h:mma")}</h5>
                  <h4>{data.location}</h4>
                  <h5>{data.address}</h5>
                  <p>Please try and get there early as there's often a rush for registration and we don't want to you to miss out</p>
                </div>
                <h3>Now you need to ...</h3>
                <div className="thing-to-do">
                  <h4>1. Put the date and location in your diary</h4>
                  <div className="add-to-calendar">
                    <AddToCalendar
                      event={event}
                      buttonWrapperClass="btn btn-info"
                      dropdownClass="link-list"
                    />
                  </div>
                </div>
                <div className="thing-to-do">
                  <h4>
                    2. Share us on Facebook<br />
                  </h4>
                  <div>
                    <div className="fb-share-button" data-href="http://www.hscsuccess.com.au?utm_campaign=sb" data-layout="button" data-size="large" data-mobile-iframe="true"><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fhscsuccess.com.au%2F%3Futm_campaign%3Dsb&src=sdkpreparse" className="fb-xfbml-parse-ignore">Share on Facebook</a></div>
                  </div>

                </div>
                <h4>3. Download your free study guide <em>Proven Strategies for HSC Success</em> to prepare for your coaching session</h4>
                <div><a href='http://emersoncoaching.com.au/Proven-Strategies.pdf' >Download your free report</a></div>
                <h3>See you at the seminar</h3>
                <div><h5>Dan Dobos<br /><a href='mailto:seminars@emersoncoaching.com.au'>seminars@emersoncoaching.com.au</a></h5></div>
              </div>
            </div>}
        </div>
      </div>
    );
  }
}

export default ThankYouSeminar;
