import moment from 'moment';
import React, { Component } from "react";
import AddToCalendar from "react-add-to-calendar";
import mainLogo from "../../assets/img/emersonLogo.jpg";
import "../../css/Home.css";
import "../../css/ThankYou.css";
import { m1PhoneForRegion } from '../../Shared/locations';

class ThankYou extends Component {
  //   let cal = createCalendar({ data: { title: "HSC Seminar - " + info, start: theDate, duration: 100 }})

  render() {
    const data = this.props.location.state
    const timezone = data.timezone
    const region = data.region
    const localTime = moment(data.starts).tz(timezone).format("dddd MMMM Do, h:mma")
    const event = {
      title: "Coaching Session",
      description:
        "Coaching Session with Emerson Coaching - contact@emersoncoaching.com.au - " +
        m1PhoneForRegion(region),
      location: "Online Video Coaching Session",
      startTime: data.starts,
      endTime: data.ends,
    };

    return (
      <div className="home-page">
        <div className="container">
          <div className="row">
            {data.redirectUrl
            ? (<div>
              <div className="col-sm-6 col-sm-offset-3">
              <div style={{ marginTop: "30px" }}>
                <img src={mainLogo} alt="" />
              </div>
              <h2 style={{ color: '#4A90E2' }}>Session time is confirmed...</h2>
              <h5 className='at'>{localTime} in {timezone.split('/')[1]} {region}</h5>

              <div className="thing-to-do">
                <h4>1. Ask the attendee to add the date and time to their diary</h4>
              </div>
              <div className="thing-to-do">
                <h4>2. Ask them to check for a confirmation SMS and email</h4>
              </div>
              <div className="thing-to-do">
                <h4><a href={data.redirectUrl}>back to the dashboard</a></h4>
              </div>
              </div>
            </div>)
            : (<div className="col-sm-6 col-sm-offset-3">
              <div style={{ marginTop: "60px", marginBottom: "30px", textAlign:'center' }}>
                <img src={mainLogo} alt="" />
              </div>
              <div className="location" style={{background: '#ffd468', padding:'20px', textAlign:'center'}}>
                <p style={{fontSize: '25px', fontWeight: 'bold'}}>Your session is booked for</p>
                <h1 style={{fontSize: '30px', fontWeight: 'bold', marginTop: "1rem", marginBottom: "5rem"}} className='at'>{localTime}</h1>
                <h3 style={{fontStyle:'italic', marginBottom: '2rem'}}>Please add this time to your diary.</h3>
                <div className="add-to-calendar">
                  <AddToCalendar
                    event={event}
                    buttonWrapperClass="btn btn-info"
                    dropdownClass="link-list"
                  />
                </div>
              </div>
              <h1 style={{ fontWeight: 'bold', textAlign:'center' }}>IMPORTANT</h1>
              <h3 style={{ textAlign:'center' }}>For session to process, we need to speak to a parent to confirm some details.</h3>
              <h3 style={{ textAlign:'center' }}>Please add 0489 077 741 to your Phone Contacts so you don't miss our call.</h3>

            </div>)
            }

          </div>
        </div>
      </div >
    );
  }
}

export default ThankYou;
